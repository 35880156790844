@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&Poppins:wght@400;600;700&display=swap');
@import "./styles/_main.scss";
@import "./styles/_copyright.scss";
@import "./styles/_login.scss";
@import "./styles/_header.scss";
@import "./styles/_pagination.scss";
@import "./styles/_slider.scss";
@import "./styles/_page-not-found.scss";
@import "./styles/_component.scss";
@import "./styles/_custom.scss";
@import "./styles/_checkbox.scss";
@import "./styles/_footer.scss";
@import "./styles/_table.scss";
@import "./styles/_profile.scss";
@import "./styles/_dashboard.scss";
@import "./styles/_header.scss";
@import "./styles/_reports.scss";
@import "./styles/_additive-detail.scss";



body {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pt-sans {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.poppins {
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #659d24;
}

.error-Danger {
  border: 2px solid #D41C27 !important;
  color: #D41C26 !important;
}

.error-Danger:focus {
  border-color: #D41C26 !important;
  color: #D41C26 !important;
}

.error-Danger:focus::placeholder {
  color: #D41C26 !important;
}

.error-Danger::placeholder {
  color: #D41C26 !important;
}

.error-Danger:focus:-ms-input-placeholder {
  color: #D41C26 !important;
}

.error-Danger:focus::-ms-input-placeholder {
  color: #D41C26 !important;
}

.error-msz {
  color: #D41C27 !important;
  font-size: 12px;
  width: 100%;
  display: block;
  height: 16px;
  bottom: -17px;
  position: absolute;
  left: 0px;
  margin: 0px;
}

.react-select__control {
  border-radius: 9999px;
  border: 1px solid #D1D5DB;
  padding: 0.25rem;
  width: 100%;
  box-shadow: none;
}

.react-select__control:hover {
  border-color: #A0AEC0;
}

.react-select__multi-value {
  border-radius: 9999px;
}

.react-select__multi-value__label {
  border-radius: 9999px;
}

.react-select__multi-value__remove {
  border-radius: 9999px;
}
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* Header section */
.header {
  position: sticky; /* Keep header height fixed */
  top: 0;
  z-index: 1000;
  background-color: #fff;
}
/* Main content area */
.content {
  flex-grow: 2; /* Take up all the remaining space */
  display: grid;
  grid-template-columns: 2fr 1fr; /* Adjust columns as needed */
  gap: 20px;
}
/* Footer section */
.footer {
  flex-shrink: 0; /* Keep footer height fixed */
}
.input-container,
.select-container {
  margin-bottom: 1px;
}

.input-container label,
.select-container label {
  display: block;
  font-weight: bold;
  color: #000000;
  /* Match the color used in other parts */
  margin-bottom: 6px;
}

.input-container input {
  width: 100%;
  padding: 12px;
  border: 2px solid #E9E9E9;
  /* Match the border style */
  border-radius: 8px;
  font-size: 1rem;
  color: #000000;
  /* Match the text color */
  background-color: #fff;
  /* Match the background color */
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input-container input:focus {
  border-color: #7FB541;
  /* Match the hover color */
}

.select-container {
  margin-bottom: 20px;
}

.btn-outline-success {
  color: #7FB541 !important; /* Custom text color */
  border-color: #7FB541 !important; /* Custom border color */
}

.btn-outline-success:hover {
  background-color: #7FB541 !important;
  color: white !important;
}

.select-container label {
  display: block;
  font-weight: bold;
  color: #000000;
  /* Match the color used in other parts */
  margin-bottom: 8px;
}

.select-container select {
  width: 100%;
  padding: 12px;
  border: 2px solid #E9E9E9;
  /* Match the border style */
  border-radius: 8px;
  font-size: 1rem;
  color: #000000;
  /* Match the text color */
  background-color: #fff;
  /* Match the background color */
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.select-container select:focus {
  border-color: #7FB541;
  /* Match the hover color */
}

.select-container select option {
  padding: 12px;
  /* Add padding for options for better visibility */
}

.select-container select option:hover {
  background-color: #7FB541 !important;
  color: #fff;
}

.select-container select option[selected] {
  background-color: #7FB541;
  /* Green background color for selected value */
  color: #fff;
  /* White text color for contrast */
}

.textarea-container {
  margin-bottom: 8px;
}

.textarea-container label {
  display: block;
  font-weight: bold;
  color: #000000;
  /* Match the color used in other parts */
  margin-bottom: 1px;
}

.textarea-container textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #E9E9E9;
  /* Match the border style */
  border-radius: 8px;
  /* Use rounded borders similar to input */
  font-size: 1rem;
  color: #000000;
  /* Default text color */
  background-color: #fff;
  /* Match the background color */
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.textarea-container textarea:focus {
  border-color: #7FB541;
  /* Match the hover color */
}

.textarea-container textarea::placeholder {
  color: #000000;
  /* Placeholder text color */
}

.custom-select__control {
  width: 100%;
  padding: 6px;
  border: 2px solid #E9E9E9;
  border-color: #E9E9E9 !important;
  /* Set default border color */
  border-radius: 8px;
  font-size: 1rem;
  color: #6C7789;
  background-color: #fff;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.custom-select__control:hover {
  border-color: #7FB541 !important;
}

.custom-select__control:focus {
  border-color: #7FB541 !important;
}

.custom-select__option {
  padding: 12px;
}

.custom-select__option:hover {
  background-color: #7FB541;
  color: #fff;
}

.custom-select__option--is-selected {
  background-color: #7FB541 !important;
  color: #fff !important;
}

.custom-select__single-value {
  color: #7FB541;
  /* Set the color for the selected value */
}

.custom-select__control:focus .custom-select__indicators {
  border-color: #7FB541 !important;
  /* Adjust the color of indicators on focus */
}

.checkbox label {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 19px;
  margin: 0;
}

.checkbox label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 3, 6, 0.5);
  background-color: #fff;
  float: right;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 420px;
}

.checkbox label:after {
  display: inline-block;
  width: 20px;
  line-height: 23px;
  cursor: pointer;
  height: 20px;
  float: right;
  border: 1px solid rgba(0, 1, 2, 0.5);
  border-radius: 4px;
  margin-right: 420px;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  display: none;
}

.checkbox input[type="checkbox"]:checked + label:after {
  background: #96BE4F url('assets/images/tick-white.svg') no-repeat;
  content: "";
  border-color: #96BE4F;
  background-position: center;
  background-size: 16px;
}

.checkbox input[type="checkbox"]:checked + label:before {
  display: none;
}


/*newly custom css for codon optimization tickbox*/

/* Custom checkbox styling */
.checkboxnew {
  display: none; /* Hide default checkbox appearance but keep it visible */
}

/* Create a custom checkbox appearance using a label */
.checkboxnew-label {
  position: relative;
  padding-left: 25px; /* Adjust for label spacing */
  cursor: pointer;
}

/* Create a custom checkbox style */
.checkboxnew-label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px; /* Size of the checkbox */
  height: 18px; /* Size of the checkbox */
  border: 2px solid #ccc; /* Default border color */
  background-color: white; /* Background color */
  border-radius: 3px; /* Optional rounded corners */
}

/* Style the checkbox when checked */
.checkboxnew:checked + .checkboxnew-label:before {
  background: #96BE4F url('assets/images/tick-white.svg') no-repeat center; /* Background with tick */
  background-size: 16px; /* Size of the tick */
  border-color: #96BE4F; /* Border color when checked */
}

/* Optional: Change the label text color when checked */
.checkboxnew:checked + .checkboxnew-label {
  color: #000000; /* Change text color */
}

.loader-gene {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #16a34a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}